import React, { FC, useRef, useState } from "react"

import Layout from "../components/layout"
// nodejs library that concatenates classes
import classnames from "classnames"
// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Badge,
} from "reactstrap"
import { useSnackbar } from "notistack"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import ReCAPTCHA from "react-google-recaptcha"

function encode(data: Record<string, string>) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const IndexPage: FC = () => {
  const [nameFocused, setNameFocused] = useState(false)
  const [emailFocused, setEmailFocused] = useState(false)
  const [formSubmission, setFormSubmission] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const contactForm = useRef<HTMLFormElement>(null)
  const title = "Home"
  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const [state, setState] = React.useState<Record<string, string | null>>({})

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formValid = state["name"] && state["email"] && state["message"]

    if (!formValid) {
      setFormSubmission(true)
      return
    } else {
      setFormSubmission(false)
    }

    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name") || "",
        "g-recaptcha-response": recaptchaRef.current?.getValue() || "",
        ...state,
      }),
    })
      .then(() =>
        enqueueSnackbar("Message sent", {
          variant: "success",
          anchorOrigin: { horizontal: "left", vertical: "bottom" },
        }),
      )
      .then(() => {
        setState({})
        contactForm.current?.reset()
      })
      .catch((error) =>
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { horizontal: "left", vertical: "bottom" },
        }),
      )
  }

  return (
    <Layout>
      <GatsbySeo title={title} openGraph={{ title: title }} />
      <div className="position-relative">
        {/* shape Hero */}
        <section className="section section-lg section-shaped pb-250">
          <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="py-lg-md d-flex">
            <div className="col px-0">
              <Row>
                <Col lg="6">
                  <h1 className="display-3 text-white">About Us</h1>
                  <p className="lead text-white">
                    We are a technical company with over ten years experience in software
                    development. We specialise in providing bespoke services for companies of all
                    sizes. We design software that will improve efficiency and productivity.
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
        {/* 1st Hero Variation */}
      </div>
      <section className="section section-lg pt-lg-0 mt--200">
        <Container>
          <Row className="justify-content-center">
            <Col lg="12">
              <Row className="row-grid">
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                        <i className="ni ni-books" />
                      </div>
                      <h6 className="text-primary text-uppercase">CMS</h6>
                      <p className="description mt-3">
                        We can help maintain/extend your existing CMS system, we have experience
                        from WordPress to Sitecore implementations.
                      </p>
                      <p className="description mt-3">
                        If you are not currently using any CMS system for your web site, we can help
                        create one for you, we will always advise depending your budget and your
                        teams experience.
                      </p>
                      <div>
                        <Badge color="primary" pill className="mr-1">
                          design
                        </Badge>
                        <Badge color="primary" pill className="mr-1">
                          system
                        </Badge>
                        <Badge color="primary" pill className="mr-1">
                          creative
                        </Badge>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5">
                      <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                        <i className="ni ni-archive-2" />
                      </div>
                      <h6 className="text-success text-uppercase">Database</h6>
                      <p className="description mt-3">
                        No complex web/app is without a database, we can help design/extend your
                        existing database; we can also help with maintenance of your database.
                      </p>
                      <p className="description mt-3">
                        We have extensive experience on various database system, from traditional
                        RDBMS to NOSQL
                      </p>
                      <div>
                        <Badge color="success" pill className="mr-1">
                          business
                        </Badge>
                        <Badge color="success" pill className="mr-1">
                          vision
                        </Badge>
                        <Badge color="success" pill className="mr-1">
                          success
                        </Badge>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5">
                      <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                        <i className="ni ni-atom" />
                      </div>
                      <h6 className="text-warning text-uppercase">Bespoke Applications</h6>
                      <p className="description mt-3">
                        We can help you develop your own application for IOS, Android to Desktop. We
                        primarily work with .Net/Java technologies, but we can develop on other
                        software platforms. We will always work with your team’s strength and your
                        budget.
                      </p>
                      <p className="description mt-3">
                        We have helped many organisations over the years, they ranged from small
                        Interior Designer firms to large Financial Institutions.
                      </p>
                      <div>
                        <Badge color="warning" pill className="mr-1">
                          marketing
                        </Badge>
                        <Badge color="warning" pill className="mr-1">
                          product
                        </Badge>
                        <Badge color="warning" pill className="mr-1">
                          launch
                        </Badge>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section pb-0 bg-gradient-warning">
        <Container>
          <Row className="row-grid align-items-center">
            <Col className="order-lg-2 ml-lg-auto" md="6">
              <div className="position-relative pl-md-5">
                <img
                  alt="..."
                  className="img-center img-fluid"
                  src={require("../assets/img/ill/ill-2.svg")}
                />
              </div>
            </Col>
            <Col lg="6">
              <Card className="bg-gradient-secondary shadow">
                <CardBody className="p-lg-5">
                  <h4 className="mb-1">Want to work with us?</h4>
                  <p className="mt-0">Your project is very important to us.</p>
                  <form
                    name="contact"
                    method="POST"
                    data-netlify-recaptcha="true"
                    data-netlify="true"
                    onSubmit={handleSubmit}
                    ref={contactForm}
                  >
                    <input type="hidden" name="form-name" value="contact" />
                    <FormGroup
                      className={classnames("mt-5", {
                        focused: nameFocused,
                        "has-success": formSubmission && state["name"],
                        "has-danger": formSubmission && !state["name"],
                      })}
                    >
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-user-run" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Your name"
                          name="name"
                          type="text"
                          onChange={handleChange}
                          onFocus={() => setNameFocused(true)}
                          onBlur={() => setNameFocused(false)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: emailFocused,
                        "has-success": formSubmission && state["email"],
                        "has-danger": formSubmission && !state["email"],
                      })}
                    >
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email address"
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onFocus={() => setEmailFocused(true)}
                          onBlur={() => setEmailFocused(false)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        "mb-4": true,
                        "has-success": formSubmission && state["message"],
                        "has-danger": formSubmission && !state["message"],
                      })}
                    >
                      <Input
                        className="form-control-alternative"
                        cols="80"
                        name="message"
                        onChange={handleChange}
                        placeholder="Type a message..."
                        rows="4"
                        type="textarea"
                      />
                    </FormGroup>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY || "asd"}
                    />
                    <div>
                      <Button block className="btn-round" color="default" size="lg" type="submit">
                        Send Message
                      </Button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* SVG separator */}
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-white" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
